import React, { useEffect, useState } from "react";
import { FaSquare } from "react-icons/fa";
import Loading from "./Loading";
import BlogCard from "./blog-card";
import { httpReauest } from "api/api";
import { useTranslation } from "react-i18next";

const BlogOtherSection = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState();

  const { t } = useTranslation("global");

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    setLoading(true);
    await httpReauest("GET", "/blogs?oldest=true", {}, {}).then(
      (res) => {
        if (res.data.data) {
          setData(res.data.data);
        }
      }
    );
    setLoading(false);
  }

  return (
    <div>
      <p
        className="d-flex justify-content-center align-items-center text-muted gap-2"
        style={{ fontSize: "16px" }}
      >
        <FaSquare size={12} color="#F37324" />
        {t("news-other-text")}
      </p>
      <h2
        className="text-center text-brand font-weight-bold mt-1 "
        style={{ fontSize: "24px", fontWeight: "bold" }}
      >
        {t("news-other-title")}
      </h2>
      <div className="mt-4 pb-4 row">
        {loading ? (
          <Loading />
        ) : (
          data?.map((item, index) => (
            <div className="col-sm-6 col-md-4 col-lg-3 mb-4" key={index}>
              <BlogCard data={item} />
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default BlogOtherSection;
