import { CDN_BASE_URL } from "pages/Request";
import React from "react";
import { useTranslation } from "react-i18next";
import { FaCalendar, FaCalendarAlt } from "react-icons/fa";
import { Link } from "react-router-dom";

const BlogCard = ({ data }) => {
  const { t } = useTranslation("global");

  const now = new Date();
  const newTime = now.getTime() - new Date(data.createdAt).getTime();
  const hour = newTime / 86400000;

  return (
    <Link to={"/blogs/" + data?.url}>
      <div class="card">
        <img
          src={CDN_BASE_URL + data?.cover}
          class="card-img-top"
          alt={data?.name}
          style={{height:"210px" , objectFit:"cover"}}
        />
        <div class="card-body">
          <h5 class="card-title">{data?.name}</h5>
          <p class="card-text line-clamp">{data?.desc}</p>
          <a href="#" class="btn btn-brand">
            بیشتر
          </a>
        </div>
      </div>
    </Link>
  );
};

export default BlogCard;
