import BlogBestSection from "components/BlogBestSection";
import BlogOtherSection from "components/BlogOtherSection";
import Header from "components/header";
import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const BlogPage = () => {
  const [t] = useTranslation("global");
  return (
    <>
      <Helmet>
        <title>{t("knockweb") + " - " + t("blog")}</title>
        <meta name="description" content={t("home-desc")} />
        <meta name="keywords" content={t("home-keywords")} />
        <meta name="og:description" content={t("home-desc")} />
        <meta
          name="og:title"
          content={t("knockweb") + " - " + t("blog")}
        />
      </Helmet>
      <Header />
      <div className="bg-light p-3 text-black mt-10">
        <div className="my-5 container">
          <BlogBestSection />
        </div>
        <div className="my-5 container">
          <BlogOtherSection />
        </div>
      </div>
    </>
  );
};

export default BlogPage;
