import Header from "components/header";
import { Example_data } from "data/example";
import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { BASE_URL, CDN_BASE_URL } from "./Request";
import Background from "components/Background";
import About from "components/About";
import AboutSection2 from "components/AboutSection2";

const AboutPage = () => {


  const [t] = useTranslation("global");

  return (
    <>
      <Helmet>
        <title>{t("knockweb") + " - " + t("about-header")}</title>
        <meta name="description" content={t("home-desc")} />
        <meta name="keywords" content={t("home-keywords")} />
        <meta name="og:description" content={t("home-desc")} />
        <meta
          name="og:title"
          content={t("knockweb") + " - " + t("about-header")}
        />
      </Helmet>
      <Background />
      <Header />
      <main
        style={{ zIndex: "100", position: "relative", padding: "3.75rem 0" }}
        id="main"
        data-aos="fade"
        data-aos-delay="1500"
      >
        <AboutSection2 />
        <About />
      </main>
    </>
  );
};

export default AboutPage;
