import React from "react";
import { useTranslation } from "react-i18next";
import WebsiteAnimation from "../animations/Contact3.json";
import Lottie from "lottie-react";
import Reveal from "motion/Reveal";
import { FaEnvelope, FaInstagram, FaPhone, FaTelegram } from "react-icons/fa";
import { Link } from "react-router-dom";

const Contact = ({ aboutref }) => {
  const [t, i18n] = useTranslation("global");
  return (
    <section
      id="contact"
      className="hero justify-content-center align-items-center d-flex about"
    >
      <div className="container">
        <div
          ref={aboutref}
          id="about"
          className="row gy-4 justify-content-center"
        >
          <div className="col-lg-5 content my-5 order-md-1 order-2 my-md-0">
            <Reveal>
              <h2>{t("contact")}</h2>
            </Reveal>
            <Reveal>
              <p
                className="pt-2 "
                dangerouslySetInnerHTML={{ __html: t("contact-desc") }}
              ></p>
            </Reveal>
            <Reveal>
              <div class="header-social-links mb-4 mt-1">
                <a
                  title={t("email")}
                  href="https://t.me/alinaderi789"
                  class="info-item d-flex"
                  target="_blank"
                  rel="noreferrer"
                  className="telegram"
                >
                  <FaTelegram />
                </a>
                <a
                  title={t("email")}
                  href="mailto:malinaderi1@outlook.com"
                  class="info-item d-flex"
                  target="_blank"
                  rel="noreferrer"
                  className="instagram"
                >
                  <FaEnvelope />
                </a>
                <a
                  title={t("instagram")}
                  href="https://www.instagram.com/1alinaderi/"
                  class="info-item d-flex"
                  target="_blank"
                  rel="noreferrer"
                  className="envelope"
                >
                  <FaInstagram />
                </a>
              </div>
            </Reveal>

            <Reveal>
              <Link to="/request" className="btn-get-started z-10">
                {t("request-form")}
              </Link>
            </Reveal>
          </div>
          <div className="col-lg-5">
            <Reveal head>
              <Lottie animationData={WebsiteAnimation} loop={true} />
            </Reveal>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
