import Home from "pages/Home";
import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Request from "pages/Request";
import ExamplePage from "pages/ExamplePage";
import { useTranslation } from "react-i18next";
import AboutPage from "pages/AboutPage";
import ScrollToTop from "components/ScrooltoTop";
import PortfolioPage from "pages/PortfolioPage";
import BlogPage from "pages/BlogPage";
import BlogDetailsPage from "pages/BlogDetailsPage";

function App() {
  const [t] = useTranslation("global");
  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <>
          <Home />
        </>
      ),
    },
    {
      path: "/request",
      element: (
        <>
          <Request />
          <ScrollToTop />
        </>
      ),
    },
    {
      path: "/about",
      element: (
        <>
          <AboutPage />
          <ScrollToTop />
        </>
      ),
    },
    {
      path: "/portfolio/:name",
      element: (
        <>
          <ExamplePage />
          <ScrollToTop />
        </>
      ),
    },
    {
      path: "/portfolio",
      element: (
        <>
          <PortfolioPage />
          <ScrollToTop />
        </>
      ),
    },
    {
      path: "/blogs",
      element: (
        <>
          <BlogPage />
          <ScrollToTop />
        </>
      ),
    },
    {
      path: "/blogs/:url",
      element: (
        <>
          <BlogDetailsPage />
          <ScrollToTop />
        </>
      ),
    },
  ]);

  return (
    <>
      <RouterProvider router={router} />
      <footer className="footer">
        <p>{t("copyright")}</p>
      </footer>
    </>
  );
}

export default App;
