import React from "react";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import { Link } from "react-router-dom";
import { Example_data } from "data/example";
import Reveal from "motion/Reveal";
import { CDN_BASE_URL } from "pages/Request";

const Example = () => {
  const [t, i18n] = useTranslation("global");

  return (
    <section
      id="portfolio"
      className="hero justify-content-center align-items-center d-flex about"
    >
      <div className="container">
        <div class="row d-flex justify-content-center">
          <div class="col-12 text-center ">
            <div className="d-flex flex-column content align-items-center  justify-contnet-center">
              <Reveal width="fit-content">
                <h2>{t("example")}</h2>
              </Reveal>
              <Reveal width="fit-content">
                <p>{t("example-text")}</p>
              </Reveal>
            </div>

            <div className="d-flex gap-3 flex-column mt-4 d-lg-none">
              {Example_data?.map((e, i) => (
                <Link to={"/portfolio/" + e.name} className="example_card">
                  {" "}
                  <img src={e.cover} alt={e.name} loading="lazy" />
                </Link>
              ))}
            </div>

            <Swiper
              modules={[Autoplay]}
              autoplay={{ delay: 4000 }}
              loop
              breakpoints={{
                992: { slidesPerView: 2 },
                552: { slidesPerView: 2 },
                120: { slidesPerView: 1 },
              }}
              className="mt-4 d-lg-block d-none"
              dir="ltr"
            >
              {Example_data?.map((e, i) => {
                return (
                  <SwiperSlide key={i}>
                    <Link to={"/portfolio/" + e.name} className="example_card">
                      {" "}
                      <img src={e.cover} alt={e.name} loading="lazy" />
                    </Link>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Example;
