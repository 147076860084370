import React from "react";
import { useTranslation } from "react-i18next";
import WebsiteAnimation from "../animations/Website.json";
import Lottie from "lottie-react";
import Reveal from "motion/Reveal";
import { Link } from "react-router-dom";

const About = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <section
      id="about"
      className="hero justify-content-center align-items-center d-flex about"
    >
      <div className="container">
        <div className="row gy-4 justify-content-center">
          <div className="col-lg-4">
            <Reveal head>
              <Lottie animationData={WebsiteAnimation} loop={true} />
            </Reveal>
          </div>
          <div className="col-lg-5 content">
            <Reveal>
              <h2>{t("about")}</h2>
            </Reveal>
            <Reveal>
              <p
                className="py-2"
                dangerouslySetInnerHTML={{ __html: t("about-text-1") }}
              ></p>
            </Reveal>
            <div className="row">
              <div className="col-lg-6">
                <ul>
                  <Reveal>
                    <li>
                      <strong>{t("about-website.0")}</strong>
                    </li>
                  </Reveal>
                  <Reveal>
                    <li>
                      <strong>{t("about-website.1")}</strong>
                    </li>
                  </Reveal>
                  <Reveal>
                    <li>
                      <strong>{t("about-website.2")}</strong>
                    </li>
                  </Reveal>
                  <Reveal>
                    <li>
                      <strong>{t("about-website.3")}</strong>
                    </li>
                  </Reveal>
                </ul>
              </div>
              <div className="col-lg-6">
                <ul>
                  <Reveal>
                    <li>
                      <strong>{t("about-website.4")}</strong>
                    </li>
                  </Reveal>
                  <Reveal>
                    <li>
                      <strong>{t("about-website.5")}</strong>
                    </li>
                  </Reveal>
                  <Reveal>
                    <li>
                      <strong>{t("about-website.6")}</strong>
                    </li>
                  </Reveal>
                  <Reveal>
                    <li>
                      <strong>{t("about-website.7")}</strong>
                    </li>
                  </Reveal>
                </ul>
              </div>
              
            </div>
            <Reveal>
              <Link to="/request" className="btn-get-started z-10">
                {t("request-form")}
              </Link>
            </Reveal>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
