import React from "react";
import { useTranslation } from "react-i18next";
import WebsiteAnimation from "../animations/Website.json";
import Lottie from "lottie-react";
import Reveal from "motion/Reveal";
import { Link } from "react-router-dom";

const AboutSection2 = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <section className="justify-content-center align-items-center d-flex about">
      <div className="container">
        <div className="row py-4 justify-content-center">
          <div className="col-lg-5 content order-2 order-lg-1">
            <Reveal>
              <h2>{t("about-title-2")}</h2>
            </Reveal>
            <Reveal>
              <p
                className="py-2"
                dangerouslySetInnerHTML={{ __html: t("about-section-2") }}
              ></p>
            </Reveal>
          </div>
          <div className="col-lg-6 order-1 order-lg-2 my-4 my-lg-0">
            <Reveal head>
              <img
                src="/2.jpg"
                className="w-100 shadow-green me-lg-4"
                alt="About"
              />
            </Reveal>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection2;
