import { httpReauest } from "api/api";
import BlogBestSection from "components/BlogBestSection";
import BlogOtherSection from "components/BlogOtherSection";
import Header from "components/header";
import Loading from "components/Loading";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { FaCheck, FaCopy } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import { CDN_BASE_URL } from "./Request";

const BlogDetailsPage = () => {
  const [t] = useTranslation("global");
  const [data, setData] = useState();
  const [related, setrelated] = useState();
  const [loading, setloading] = useState(true);
  const { url } = useParams();

  useEffect(() => {
    getData();
  }, [url]);

  async function getData(params) {
    setloading(true);
    window?.scroll({ top: 0 });
    const res = await httpReauest("GET", "/blogs/" + url, {}, {});
    setData(res.data.data.blog);
    setrelated(res.data.data.related);
    setloading(false);
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <title>{data?.name}</title>
        <meta name="description" content={data?.meta_desc} />
        <meta name="keywords" content={data?.meta_keywords} />
        <meta name="og:description" content={data?.meta_desc} />
        <meta name="og:title" content={data?.name} />
      </Helmet>
      <Header />
      <div
        style={{ fontFamily: "Yekan" }}
        className="bg-light p-lg-3 pt-1 text-black mt-10"
      >
        <div className="my-3 my-lg-0 container ">
          <div class="">
            <div class="row">
              <div class="col-12 col-lg-8 border-bottom">
                <div className="invite font-small">
                  <h3 className=" fs-5 fw-bold m-0">
                    همین حالا برای ایجاد سایت خودت با ما ارتباط بگیر
                  </h3>
                  <Link
                    className="btn-brand btn"
                    style={{ fontSize: "14px" }}
                    to={"/request"}
                  >
                 مشاوره رایگان
                  </Link>
                </div>
                <div class="mb-4">
                  <h2 class="display-6 mt-lg-3 fw-bold  ">{data?.name}</h2>
                  <p class="mt-3">{data?.desc}</p>
                </div>
                <div>
                  <img
                    src={`${CDN_BASE_URL}${data?.cover}`}
                    class="img-fluid rounded w-100"
                    style={{ maxHeight: "480px", objectFit: "cover" }}
                    alt={data?.cover}
                  />
                </div>

                <div class="mt-4">
                  <div
                    className="font-small"
                    dangerouslySetInnerHTML={{ __html: data?.content }}
                  />
                </div>
              </div>
              <div className="col-lg-4 d-none d-lg-block">
                <div className="border rounded p-2">
                  <h3 className="fs-4 fw-bold">بلاگ های مرتبط</h3>
                  <div className="mt-3 gap-3 d-flex flex-column w-100">
                    {related?.map((item) => (
                      <Link
                        to={"/blogs/" + item.url}
                        style={{ height: "95px" }}
                        className="border rounded p-1 d-flex w-100 gap-2"
                      >
                        <img
                          src={CDN_BASE_URL + item?.cover}
                          className=" rounded"
                          style={{ objectFit: "cover", width: "35%" }}
                          alt={item.name}
                        />
                        <span
                          style={{ width: "65%" }}
                          className=" d-flex h-100 flex-column justify-content-between align-items-end p-1"
                        >
                          <h5 className="fw-bold fs-6 text-black w-100 line-clamp-2">
                            {item.name}
                          </h5>
                          <button className="btn-outline-primary-2">
                            مشاهده
                          </button>
                        </span>
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container mt-5">
          <BlogBestSection />
        </div>
      </div>
    </>
  );
};

export default BlogDetailsPage;
