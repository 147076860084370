import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaAngleDown, FaList, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";

const Header = () => {
  const [t, i18n] = useTranslation("global");

  const handleChangeLanguge = (e) => {
    i18n.changeLanguage(e);
  };

  useEffect(() => {
    if (i18n.language == "fa") {
      document.dir = "rtl";
    } else {
      document.dir = "ltr";
    }
  }, [i18n.language]);

  const [show, setShow] = useState();
  const [show2, setShow2] = useState();

  return (
    <header
      dir="ltr"
      id="header"
      class="header d-flex align-items-center fixed-top"
    >
      <div class="container-fluid d-flex align-items-center justify-content-between">
        <Link to="/" class="logo d-flex align-items-center  me-auto me-lg-0">
          <img src="/logo.png" alt="" />
        </Link>

        <nav
          style={{ right: show && "0" }}
          id="navbar"
          class="navbar"
          dir="rtl"
        >
          <ul>
            <li>
              <Link to="/">{t("home")}</Link>
            </li>
            <li>
              <Link to="/about">{t("about-header")}</Link>
            </li>
            <li>
              <Link to="/portfolio">{t("example")}</Link>
            </li>
            <li>
              <Link to="/request">{t("contact")}</Link>
            </li>
            <li>
              <Link to="/request">{t("request")}</Link>
            </li>
            <li>
              <Link to="/blogs">{t("blog")}</Link>
            </li>
          </ul>
        </nav>

        <div class="header-social-as">
          <div className="order-1 order-lg-3">
            <span
              onClick={() => (show2 ? setShow2(false) : setShow2(true))}
              className="d-flex gap-2 pointer align-items-center cursor-pointer position-relative "
            >
              <span style={{ whiteSpace: "nowrap", fontSize: "14px" }}>
                {" "}
                <img
                  src={
                    i18n.language == "en"
                      ? "https://upload.wikimedia.org/wikipedia/en/a/ae/Flag_of_the_United_Kingdom.svg"
                      : "https://upload.wikimedia.org/wikipedia/commons/c/ca/Flag_of_Iran.svg"
                  }
                  style={{
                    width: "22px",
                    height: "22px",
                    borderRadius: "50%",
                    objectFit: "cover",
                  }}
                  className="me-2"
                  alt=""
                />
                {i18n.language == "en" ? "English - EN" : "فارسی - FA"}
              </span>
              <span>
                <FaAngleDown />
              </span>
              {show2 && (
                <span className={`drop_down`}>
                  <span
                    onClick={() => handleChangeLanguge("en")}
                    className="d-flex gap-2 pointer align-items-center "
                  >
                    <img
                      src="https://upload.wikimedia.org/wikipedia/en/a/ae/Flag_of_the_United_Kingdom.svg"
                      style={{
                        width: "22px",
                        height: "22px",
                        borderRadius: "50%",
                        objectFit: "cover",
                      }}
                      alt=""
                    />
                    <span> English - EN</span>
                  </span>
                  <span
                    onClick={() => handleChangeLanguge("fa")}
                    className="d-flex gap-2 pointer align-items-center "
                  >
                    <img
                      src="https://upload.wikimedia.org/wikipedia/commons/c/ca/Flag_of_Iran.svg"
                      style={{
                        width: "22px",
                        height: "22px",
                        borderRadius: "50%",
                        objectFit: "cover",
                      }}
                      alt=""
                    />
                    <span> فارسی - FA</span>
                  </span>
                </span>
              )}
            </span>
          </div>
        </div>
        {show ? (
          <i
            onClick={() => setShow(false)}
            class="mobile-nav-toggle mobile-nav-hide "
          >
            <FaTimes />
          </i>
        ) : (
          <i
            onClick={() => setShow(true)}
            className="mobile-nav-toggle mobile-nav-show"
          >
            <FaList />
          </i>
        )}
      </div>
    </header>
  );
};

export default Header;
