import React from "react";

const Loading = () => {
  return (
    <div
      id="spinner"
      className="show d-flex align-items-center justify-content-center"
    >
      <div
        className="spinner-border text-brand"
        style={{ width: "2rem", height: "2rem" }}
        role="status"
      ></div>
    </div>
  );
};

export default Loading;
