import Header from "components/header";
import { Example_data } from "data/example";
import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Background from "components/Background";
import Reveal from "motion/Reveal";
import { Link } from "react-router-dom";

const PortfolioPage = () => {
  const [t] = useTranslation("global");

  return (
    <>
      <Helmet>
        <title>{t("knockweb") + " - " + t("example")}</title>
        <meta name="description" content={t("home-desc")} />
        <meta name="keywords" content={t("home-keywords")} />
        <meta name="og:description" content={t("home-desc")} />
        <meta
          name="og:title"
          content={t("knockweb") + " - " + t("example")}
        />
      </Helmet>
      <Background />
      <Header />
      <main
        style={{ zIndex: "100", position: "relative", padding: "60px 0" }}
        id="main"
        data-aos="fade"
        data-aos-delay="1500"
      >
        <div className="w-100 d-flex flex-column px-3 lg:px-5 justify-content-center align-items-center py-2 my-5 container">
          <div className="d-flex flex-column content align-items-center  justify-contnet-center">
            <Reveal width="fit-content">
              <h2>{t("example")}</h2>
            </Reveal>
            <Reveal width="fit-content">
              <p>{t("example-text")}</p>
            </Reveal>
          </div>
          <div className="row ">
            {Example_data.map((e) => (
              <Link to={"/portfolio/" + e.name} className="example_card col-lg-4">
                {" "}
                <img src={e.cover} alt={e.name} loading="lazy" />
              </Link>
            ))}
          </div>
        </div>
      </main>
    </>
  );
};

export default PortfolioPage;
